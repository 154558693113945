var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "m-subheader "
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-auto"
  }, [_c('h3', {
    staticClass: "m-subheader__title m-subheader__title--separator"
  }, [_vm._v(" " + _vm._s(_vm.$route.meta.label) + " ")]), _c('p', {
    staticClass: "m-nav m-nav--inline"
  })])])]), _c('div', {
    staticClass: "m-content"
  }, [_c('div', {
    staticClass: "m-portlet__body"
  }, [_c('vue-dropzone', {
    ref: "dropzoneVerification",
    staticClass: "m-dropzone dropzone",
    attrs: {
      "id": "dropzone-verification",
      "options": _vm.dropzoneOptions
    },
    on: {
      "vdropzone-success": _vm.dropzoneSuccess
    }
  })], 1), _vm.verificationSuccess ? _c('div', {
    staticClass: "d-flex align-items-right"
  }, [_vm.downloading ? _c('div', [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "voucher": "Loading..."
    }
  })], 1) : _c('button', {
    staticClass: "btn m-btn--pill m-btn--air btn-success",
    staticStyle: {
      "margin": "18px",
      "width": "85%"
    },
    on: {
      "click": _vm.downloadData
    }
  }, [_vm._v(" Download Hasilnya ")])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }