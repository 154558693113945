<template>
  <div>
    <div class="m-subheader ">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            {{ $route.meta.label }}
          </h3>
          <p class="m-nav m-nav--inline"></p>
        </div>
      </div>
    </div>
    <!-- END: Subheader -->
    <div class="m-content">
      <div class="m-portlet__body">
        <vue-dropzone id="dropzone-verification" ref="dropzoneVerification" class="m-dropzone dropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneSuccess" />
      </div>
      <div v-if="verificationSuccess" class="d-flex align-items-right">
        <div v-if="downloading">
          <vue-simple-spinner size="large" voucher="Loading..."></vue-simple-spinner>
        </div>
        <button v-else @click="downloadData" class="btn m-btn--pill m-btn--air btn-success" style="margin: 18px; width: 85%;">
          Download Hasilnya
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'Voucher',
  mounted: function() {},
  data: function() {
    return {
      searchTerm: null,
      showProgress: true,
      counterProgress: 0,
      locked: false,
      unlockPassword: '',
      verificationSuccess: false,
      downloading: false,
      fileName: 'x',
      dropzoneOptions: {
        url: window.axios.defaults.baseURL + 'api/absensireport/verification',
        maxFilesize: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          Authorization: 'jwt ' + this.$store.getters['customer/customer'].token
        }
      }
    }
  },
  methods: {
    dropzoneSuccess: function(file, response) {
      this.fileName = response.data
      this.verificationSuccess = true
    },
    downloadData() {
      this.downloading = true
      this.$store
        .dispatch('absensireport/DOWNLOAD_VERIFICATION', {
          fileName: this.fileName
        })
        .then(resp => {
          this.downloading = false
        })
    }
  },
  computed: {},
  created: function() {},
  components: {
    vueDropzone: vue2Dropzone
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
    }
  }
}
</script>
<style scoped>
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
</style>
